import React, {useEffect, useState} from 'react';
import _ from "lodash"
import axios from 'axios';

import TabLabel from '../Utilities/TabLabel';
import TabLoading from '../Utilities/TabLoading';

const StagesTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Stages</TabLabel>)
    axios.get(`/pipelines/${props.pipeline_id}/stages.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Stages</TabLabel>)
    }).catch((error) => {
      console.log('error loading pipeline data')
    })
  }, [])

  function render() {
    if (data) {
      return (
        <React.Fragment key="pipeline-stages">

          {Object.entries(data.stages).map(([name,apps]) => {
              return <div>
                  <h2 className="slds-text-heading_medium  slds-m-top_small slds-m-bottom_small">{name}</h2>
                  <table className="slds-table slds-table_cell-buffer slds-table_bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                  {apps.map((app) => {
                    return <tr>
                      <td><a href={`/apps/${app.name}`}>{app.name}</a></td>
                    </tr>
                  })}
                  </tbody>
                  </table>
              </div>
          })}

          </React.Fragment>
      )
    } else {
      return(<TabLoading />)
    }
  }

  return render()
}

export default StagesTab
