import React from "react"
import axios from 'axios'
import _ from "lodash"
import moment from "moment"

import Chart from 'chart.js/auto'
import {Interaction} from 'chart.js'

import 'chartjs-adapter-moment'

import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';
Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate

const ACTIVITY_EVENTS = ['restart', 'cycling', 'deploy']
const INFO_CODES = ['H27', 'H28', 'H80', 'H81', 'H82']
const WARNING_CODES = ['L10', 'L12', 'L13', 'L15']
const CRITICAL_CODES = [
  'H10', 'H12', 'H13', 'H15', 'H17', 'H18', 'H19', 'H20', 'H21', 'H26', 'H99',
  'R10', 'R12', 'R14', 'R15', 'R99'
]

class EventsChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      chart: null,
      bounds: {
        start: null,
        end: null
      },
      datasets: [
        {label: "cycling", data: [], borderColor: "rgba(0, 0, 0, 0)", backgroundColor: this.backgroundColourForType("cycling")},
        {label: "restart", data: [], borderColor: "rgba(0, 0, 0, 0)", backgroundColor: this.backgroundColourForType("restart")},
      ]
    };
  }

  componentDidMount () {
    this.getEvents()
  }

  getEvents() {
    var _this = this
    // {
    //   "start_time": "2021-08-04T20:41:34Z",
    //   "end_time": "2021-08-06T20:41:34Z",
    //   "events": [
    //       {
    //           "type": "cycling",
    //           "description": "A dyno was cycled.",
    //           "count": 1,
    //           "created_at": "2021-08-05T19:30:00Z"
    //       },
    //       {
    //           "type": "cycling",
    //           "description": "A dyno was cycled.",
    //           "count": 1,
    //           "created_at": "2021-08-05T20:40:00Z"
    //       }
    //   ]
    // }
    axios.get(`${this.props.app_id}/metrics/events`,
      {
        headers: {
          'Accept': 'application/vnd.heroku+json; version=2'
        }
      })
      .then(function (response) {

        var datasets = _this.state.datasets
        response.data.events.forEach((event) => {
          // Find dataset of existing types
          var dataset = datasets.find((e) => { return e.label == event.type })
          // If event is for a new dataset, create the dataset and add it to the main datasets
          if (dataset === undefined) {
            dataset = {label: event.type, data: [], borderColor: "rgba(0, 0, 0, 0)", backgroundColor: _this.backgroundColourForType(event.type)}
            datasets.push(dataset)
          }
          // Store X, Y position, and the count (might use this later)
          dataset.data.push({x: new Date(Date.parse(event.created_at)), y: event.type, count: event.count})
        })

        _this.setState({
          datasets: datasets
        }, () => {
          _this.getErrors()
        })

      })
      .catch(function (error) {
        console.log('Error loading Event metrics')
        console.log(error);
      })
  }

  getErrors() {
    var _this = this
    //   {
    //     "start_time": "2021-08-04T20:41:34Z",
    //     "end_time": "2021-08-06T20:41:34Z",
    //     "step": 10,
    //     "data": {
    //         "H27": [null, null, null, null, 3, null, null, null, 1, null, null, null],
    //         "H28": [null, null, null, null, 1, null, 4, null, null, null]
    //     }
    // }
    axios.get(`${this.props.app_id}/metrics/errors`,
    {headers: {
      'Accept': 'application/vnd.heroku+json; version=2'
    }})
    .then(function (response) {
      var datasets = _this.state.datasets

      Object.keys(response.data.data).forEach((type) => {
        // Find dataset of existing types
        var dataset = datasets.find((d) => { return d.label == type })
        // If event is for a new dataset, create the dataset and add it to the main datasets
        if (dataset === undefined) {
          dataset = {label: type, data: [], borderColor: "rgba(0, 0, 0, 0)", backgroundColor: _this.backgroundColourForType(type)}
          datasets.push(dataset)
        }

        // Set a timer so we can assign time values the error metrics from the array
        var currentTime = moment(response.data.start_time)
        // Loop over each error type
        response.data.data[type].forEach((v) => {
          if (v !== null) {
            dataset.data.push({x: currentTime.toDate(), y: type, count: v})
          }
          // step the timer
          currentTime.add(response.data.step, 'minutes')
        })
      })

      _this.setState({
        datasets: datasets
      }, () => {
        _this.renderChart()
      })

    })
    .catch(function (error) {
      console.log(error);
    })
  }

  backgroundColourForType(type) {
    if (ACTIVITY_EVENTS.includes(type)) {
      return "rgba(86, 205, 252, 0.5)"
    } else if (INFO_CODES.includes(type)) {
      return "rgba(150, 163, 182, 0.5)"
    } else if (WARNING_CODES.includes(type)) {
      return "rgba(250, 159, 70, 0.5)"
    } else if (CRITICAL_CODES.includes(type)) {
      return "rgba(214, 66, 66, 0.5)"
    } else { 
      return "rgba(150, 163, 182, 0.5)"
    }
  }

  getLabels() {
    return this.state.datasets.map((dataset) => { return dataset.label })
  }

  renderChart () {
    // var _this = this
    var ctx = document.getElementById("events");
    var scales = this.props.scaleConfig

    // Setting all of these individually so as to not overwrite other defaults
    scales.y.type = 'category'
    scales.y.labels = this.getLabels()
    // scales.x.gridLines.lineWidth = 0
    // scales.y.gridLines = {
    //   offsetGridLines: true
    // }
    // scales.x.ticks = {
    //   padding: 5
    // }
    // scales.x.time = {
    //   //min: 1628252063077,
    //   //max: 1628368134423,
    //   displayFormats: {
    //     hour: 'MMM D HH:mm'
    //   }
    // }

    scales.x.min = moment().subtract(2, 'days')
    scales.x.max = moment()

    var myChart = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: this.state.datasets
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: scales,
        plugins: {
          tooltip: {
            enabled: false,
            animate: false,
            caretPadding: 10,
            mode: 'index',
            intersect: false
          },
          crosshair: {
            sync: {
              enabled: true
            },
            line: {
              color: '#F66',  // crosshair line color
              width: 1        // crosshair line width
            },
          },
          legend: {
            display: false
          }
        },
  			elements: {
  				point: {
            pointStyle: 'rect',
  					borderWidth: 1,
            radius: 5,
            hitRadius: 0,
            hoverRadius: 0
  				}
  			}
      }
    })
    this.setState({ chart: myChart })
  }

  chartHeight() {
    return 10 + (this.getLabels().length * 35)
  }

  render () {
    return (
          <canvas id="events" width="1000" height={this.chartHeight()}></canvas>
    )
  }
}

export default EventsChart
