import React from 'react';

import Metrics from "./metrics/Metrics"
import TabLoading from '../Utilities/TabLoading';

const MetricsTab = props => {

  function render() {
    if (props.app_id !== null && props.app_id !== undefined) {
      return(
        <Metrics
                      app_id={props.app_id}
                      // created_at: @ticket.created_at.to_s,
                      auth_string={`Bearer heroku=${window.current_user.token}`}
                      metrics_endpoint="https://tenkara-api.herokai.com"
        />
      ) 
    } else { 
      return(<TabLoading />)
    }
  }

  return render()
}

export default MetricsTab