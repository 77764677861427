import _ from "lodash";
import React from "react";

import { Badge, Spinner } from "@salesforce/design-system-react";

import DefinitionItem from "../Utilities/DefinitionItem";
import PropertyValue from "../Utilities/PropertyValue";
import Warnings from "../Utilities/Warnings";
import TabLoading from "../Utilities/TabLoading"

const EventsTab = (props) => {
  function render() {
    if (props.data) {
      return (
        <React.Fragment key="app-ssl">
          <Warnings warnings={props.data.warnings} />

          <table className="slds-table slds-table_cell-buffer slds-table_bordered">
            <thead>
              <tr>
                <th>-</th>
              </tr>
            </thead>

            <tbody>
              {props.data.events.map((event) => {
                return (
                  <tr>
                    <td></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </React.Fragment>
      );
    } else {
      return (
        <TabLoading />
      );
    }
  }

  return render();
};

export default EventsTab;
