import React, {useEffect, useState} from 'react';
import axios from 'axios';

import PropertyValue from "../Utilities/PropertyValue"
import Warnings from "../Utilities/Warnings"
import TabLabel from '../Utilities/TabLabel';
import TabLoading from '../Utilities/TabLoading';

const BuildsTab = props => {

  const [data, setData] = useState(null)

    useEffect(() => {
      props.setTabLabel(<TabLabel isLoading={true}>Builds</TabLabel>)
      axios.get(`/functions/${props.app_id}/builds.json`).then((response) => {
        setData(response.data)
        props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Builds</TabLabel>)
      }).catch((error) => {
        console.log('error loading builds')
      })
    }, [])

  function render() {
    if (data !== null) {
      return(
        <React.Fragment key="function-releases">

          <Warnings warnings={data.warnings} />

          <h2 className="slds-text-title_caps slds-m-top_large slds-m-bottom_small">Builds</h2>

          <table className="slds-table slds-table_bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Created</th>
                <th>User</th>
                <th>Stack</th>
                <th>Status</th>
                <th>Output</th>
              </tr>
            </thead>
            <tbody>
              {data.builds.map((build) => {
                return(
                  <tr key={build.id} >
                    <td><PropertyValue value={build.id} /></td>
                    <td><PropertyValue value={build.created_at} /></td>
                    <td><PropertyValue value={build.user.email} /></td>
                    <td><PropertyValue value={build.stack} /></td>
                    <td><PropertyValue value={build.status} /></td>
                    <td><a href={`/apps/${build.app.id}/builds/${build.id}/output.txt`} target="_blank">Output</a></td>
                  </tr>
                )
              })}
                </tbody>
              </table>

            </React.Fragment>
      ) 
    } else { 
      return(<TabLoading />)
    }
  }

  return render()
}

export default BuildsTab
