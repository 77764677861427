import _ from "lodash";
import React, {useEffect, useState} from 'react';
import axios from "axios";

import DefinitionItem from "../Utilities/DefinitionItem"
import PropertyValue from "../Utilities/PropertyValue"
import Warnings from "../Utilities/Warnings"
import Warning from "../Utilities/Warning";
import TabLabel from "../Utilities/TabLabel";
import TabLoading from "../Utilities/TabLoading";

const DetailsTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Details</TabLabel>)
    axios.get(`/functions/${props.app_id}/details.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Details</TabLabel>)
    }).catch((error) => {
      console.log('error loading app data')
      console.log(error)
    })
  }, [])

  function render() {
    if (data !== null) {
      return(
        <React.Fragment key="function-details">

          <Warnings warnings={data.warnings} />

          <h2 className="slds-text-title_caps slds-border_bottom slds-p-bottom_x-small slds-m-bottom_xx-small slds-m-top_large">App</h2>
          <dl className="slds-dl_horizontal">
            <DefinitionItem title="Name">
              <PropertyValue value={data.app.name} clipboard={true} />
            </DefinitionItem>
            <DefinitionItem title="UUID">
              <PropertyValue value={data.app.id} clipboard={true} />
            </DefinitionItem>
          </dl>

          <h2 className="slds-text-title_caps slds-border_bottom slds-p-bottom_x-small slds-m-bottom_xx-small slds-m-top_large">Owner</h2>
          <dl className="slds-dl_horizontal">
            <DefinitionItem title="Email">
              <PropertyValue value={data.app.owner.email} clipboard={true} />
            </DefinitionItem>
            <DefinitionItem title="UUID">
              <PropertyValue value={data.app.owner.id} clipboard={true} />
            </DefinitionItem>
          </dl>

          <h2 className="slds-text-title_caps slds-border_bottom slds-p-bottom_x-small slds-m-bottom_xx-small slds-m-top_large">App Metadata</h2>
          <dl className="slds-dl_horizontal">
            <DefinitionItem title="Created">
              <PropertyValue value={data.app.created_at} />
            </DefinitionItem>
            <DefinitionItem title="Updated">
              <PropertyValue value={data.app.updated_at} />
            </DefinitionItem>
            <DefinitionItem title="Last Release">
              <PropertyValue value={data.app.released_at} />
            </DefinitionItem>
            <DefinitionItem title="Region">
              <PropertyValue value={_.get( "data.app.region.name")} />
            </DefinitionItem>
            <DefinitionItem title="Space">
              <PropertyValue value={_.get(data ,"app.space.name")} link={`/spaces/${_.get(data, "app.space.id")}`} />
            </DefinitionItem>
          </dl>

          <Warning message={data.warnings["formation.web"]} >
            <h2 className="slds-text-title_caps slds-border_bottom slds-p-bottom_x-small slds-m-bottom_xx-small slds-m-top_large">Dyno Formation</h2>
          </Warning>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Command</th>
                <th>Quantity</th>
                <th>Size</th>
              </tr>
            </thead>

            <tbody>
              {data.formation.map((dyno) => {
                return(
                  <tr key={dyno.id}>
                    <td><code>{dyno.type}</code></td>
                    <td><code>{dyno.command}</code></td>
                    <td>{dyno.quantity}</td>
                    <td>{dyno.size}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>

        </React.Fragment>
      ) 
    } else { 
      return(<TabLoading />)
    }
  }

  return render()
}

export default DetailsTab
