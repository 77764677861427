import _ from "lodash";
import React, {useEffect, useState} from 'react';
import axios from 'axios';

import DefinitionItem from "../Utilities/DefinitionItem"
import PropertyValue from "../Utilities/PropertyValue"
import Warnings from "../Utilities/Warnings"
import CommandOutput from "../Utilities/CommandOutput"
import TabLabel from "../Utilities/TabLabel";
import TabLoading from "../Utilities/TabLoading";

const SSLTab = props => {

  const [data, setData] = useState(null)

    useEffect(() => {
      props.setTabLabel(<TabLabel isLoading={true}>SSL</TabLabel>)
      axios.get(`/apps/${props.app_id}/ssl.json`).then((response) => {
        setData(response.data)
        props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>SSL ({response.data.sni_endpoints.length})</TabLabel>)
      }).catch((error) => {
        console.log('error loading ssl data')
      })
    }, [])

  function render() {
    
    if (data) {
      return(
        <React.Fragment key="app-ssl">

          <Warnings warnings={data.warnings} />
          
          {data.sni_endpoints.map((endpoint) => {
        return(
            <div key={endpoint.id} className="slds-m-bottom_xx-large">
                <h2 className="slds-text-title_caps slds-border_bottom slds-p-bottom_x-small slds-m-bottom_xx-small slds-m-top_large">{endpoint.ssl_cert.cert_domains.join(", ")}</h2>

                    <div className="slds-grid slds-wrap">
                    <dl className="slds-dl_horizontal slds-size_1-of-2 slds-m-bottom_small">
                    <DefinitionItem title="Starts at">
                        <PropertyValue value={endpoint.ssl_cert.starts_at} />
                      </DefinitionItem>
                      <DefinitionItem title="Expires at">
                        <PropertyValue value={endpoint.ssl_cert.expires_at} />
                      </DefinitionItem>
                      <DefinitionItem title="ID">
                      <PropertyValue value={endpoint.id} clipboard={true} />
                      </DefinitionItem>
                      <DefinitionItem title="Name">
                        <PropertyValue value={endpoint.name} clipboard={true} />
                      </DefinitionItem>
                      <DefinitionItem title="Domains">
                        {endpoint.ssl_cert.cert_domains.map((domain) => {
                          return <PropertyValue value={`https://${domain}`} />
                        })}
                      </DefinitionItem>
                    </dl>

                    <dl className="slds-dl_horizontal  slds-size_1-of-2 slds-m-bottom_small">
                      <DefinitionItem title="Subject">
                        <PropertyValue value={endpoint.ssl_cert.subject} />
                      </DefinitionItem>
                      <DefinitionItem title="Issuer">
                        <PropertyValue value={endpoint.ssl_cert.issuer} />
                      </DefinitionItem>
                      <DefinitionItem title="CA signed?">
                        <PropertyValue value={endpoint.ssl_cert["ca_signed?"]} />
                      </DefinitionItem>
                      <DefinitionItem title="Self Signed?">
                        <PropertyValue value={endpoint.ssl_cert["self_signed?"]} />
                      </DefinitionItem>
                      <DefinitionItem title="ACM">
                        <PropertyValue value={endpoint.ssl_cert.acm} />
                      </DefinitionItem>
                    </dl>
                    </div>
                
                <CommandOutput command={endpoint.s_client} />
            </div>
        )
      })}

      </React.Fragment>
      )
    } else {
      return(<TabLoading />)
    }
  }

  return render()
}

export default SSLTab
