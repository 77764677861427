import React, {useEffect, useState} from 'react';

import axios from 'axios'
import _ from "lodash"

import {
    Card,
    PageHeader
} from '@salesforce/design-system-react';

import {MalibuIcon} from '@heroku/react-malibu'

import PropertyValue from "./Utilities/PropertyValue"

const SearchPage = props => {

  function render() {
    return(
        <React.Fragment key={props.title}>
            <div className="slds-grid slds-gutters slds-m-horizontal_medium slds-wrap">
                <div className="slds-col slds-size_1-of-1 slds-m-bottom_large">

                    { props.app && <PageHeader
                        label="Heroku Application"
                        title={<PropertyValue value={props.app.name} link={`/apps/${props.app.id}`} clipboard={true} />}
                        icon={<MalibuIcon name="app-28" size={32} />}
                        variant="record-home"
                        className="slds-m-bottom_medium"
                    />}

                    { props.pipeline && <PageHeader
                        label="Heroku Pipeline"
                        title={<PropertyValue value={props.pipeline.name} link={`/pipelines/${props.pipeline.id}`} clipboard={true} />}
                        icon={<MalibuIcon name="pipeline-28" size={32} />}
                        variant="record-home"
                        className="slds-m-bottom_medium"
                    />}

                    { props.space && <PageHeader
                        label="Heroku Space"
                        title={<PropertyValue value={props.space.name} link={`/spaces/${props.space.id}`} clipboard={true} />}
                        icon={<MalibuIcon name="space-28" size={32} />}
                        variant="record-home"
                        className="slds-m-bottom_medium"
                    />}

                    { props.user && <PageHeader
                        label="Heroku User"
                        title={<PropertyValue value={props.user.name||props.user.email} link={`/users/${props.user.id}`} clipboard={true} />}
                        icon={<MalibuIcon name="user-28" size={32} />}
                        variant="record-home"
                        className="slds-m-bottom_medium"
                    />}

                    { props.apps && <Card>
                        <div className="slds-card__body_inner">
                            <table className="slds-table slds-table_header-hidden slds-table_bordered slds-table_edit slds-tree slds-table_tree">

                                <thead className="slds-assistive-text">
                                <tr className="slds-line-height_reset">
                                    <th>App Name</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {props.apps.map((app) => {
                                        return <tr>
                                            <td><PropertyValue value={app.name} link={`/apps/${app.name}`} /></td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Card>}
                </div>
            </div>
        </React.Fragment>
    )
  }

  return render()
}

export default SearchPage
