import _ from "lodash";
import React, {useEffect, useState} from "react";
import axios from "axios";

import {
  IconSettings,
  Icon,
  Popover
} from "@salesforce/design-system-react";

import PropertyValue from "../Utilities/PropertyValue";
import Warnings from "../Utilities/Warnings";
import TabLabel from "../Utilities/TabLabel";
import TabLoading from "../Utilities/TabLoading";

const FeaturesTab = (props) => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Features</TabLabel>)
    axios.get(`/apps/${props.app_id}/features.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Features</TabLabel>)
    }).catch((error) => {
      console.log('error loading features data')
    })
  }, [])

  function enabledFeatures() {
    return data.features.filter((x) => { return x["enabled"]}).sort((a,b) => { return a > b })
  }

  function disabledFeatures() {
    return data.features.filter((x) => { return !x["enabled"]}).sort((a,b) => { return a > b })
  }

  function render() {
    if (data !== null) {
      return (
        <React.Fragment key="app-feature">
          <Warnings warnings={data.warnings} />

          <table className="slds-table slds-table_bordered">
            <thead>
              <tr>
                <th>Enabled</th>
                <th>Name</th>
                <th></th>
                <th>Description</th>
                <th>State</th>
              </tr>
            </thead>

            <tbody>
              {enabledFeatures().concat(disabledFeatures()).map((feature) => {
                return (
                  <tr key={feature.name}>
                    <td>
                      { feature.enabled && 
                       <IconSettings iconPath="/assets/icons">
                        <Icon 
                          category="utility"
                          colorVariant="default"
                          size="xx-small"
                          name="check"
                          />
                          </IconSettings>
                      }
                    </td>
                    <td>
                      <PropertyValue value={feature.name} />
                    </td>
                    <td>
                      <IconSettings iconPath="/assets/icons">
                        <Popover
                          body={<PropertyValue value={feature.doc_url} />}
                          heading="Documentation"
                          style={{"width": "auto"}}
                        >
                          <a tabIndex="0" >
                            <Icon
                              category="utility"
                              colorVariant="light"
                              size="xx-small"
                              name="form"
                            />
                          </a>
                        </Popover>
                      </IconSettings>
                    </td>
                    <td className="slds-truncate slds-cell-wrap">
                      <PropertyValue value={feature.description} />
                    </td>
                    <td>
                      <PropertyValue value={feature.state} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </React.Fragment>
      );
    } else {
      return (<TabLoading />);
    }
  }

  return render();
};

export default FeaturesTab;
