import React from "react"
import axios from 'axios'

import Chart from 'chart.js/auto'
import {Interaction} from 'chart.js'

import 'chartjs-adapter-moment'

import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';
Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate

class ResponseTimeChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      chart: null,
      tooltipValues: {
        avg: null,
        perc95: null,
        perc99: null
      }
    };
  }

  componentDidMount () {
    // this.props.dataLoader(this, 'latency');
    const _this = this
    const type = 'latency'
    axios.get(`${this.props.app_id}/metrics/${type}`,
      {headers: {
        'Accept': 'application/vnd.heroku+json; version=2',
          // 'Authorization': this.props.auth_string
      }})
      .then(function (response) {
        _this.setState({
          data: response.data
        }, () => {
          _this.renderChart()
        })

      })
      .catch(function (error) {
        console.log(error);
      })
  }

  getDatasets () {
    var refined_data = [];
    var datasets = [];
    var _this = this;

    Object.keys(_this.state.data.data).forEach(function(set) {
      if (['latency.ms.p50', 'latency.ms.p95', 'latency.ms.p99'].includes(set)) {
        datasets.push({
          label: _this.getLabel(set),
          data: _this.state.data.data[set],
          backgroundColor: _this.getBackgroundColor(set),
          borderColor: _this.getBorderColor(set),
          borderWidth: 1,
          radius: 0,
          pointHitRadius: 10,
          stepped: "middle"
        });
      }
    });
    return datasets;
  }

  getBackgroundColor (set) {
    switch(set) {
      case 'latency.ms.p50': return '#d6cde1';
      case 'latency.ms.p95': return '#f4f2f8';
      case 'latency.ms.p99': return '#ffffff';

    }
  }

  getBorderColor (set) {
    switch(set) {
      case 'latency.ms.p50': return '#a996c2';
      case 'latency.ms.p95': return '#a996c2';
      case 'latency.ms.p99': return '#ff0000';
    }
  }

  getLabel (set) {
    switch(set) {
      case 'latency.ms.p50': return 'Median';
      case 'latency.ms.p95': return '95th Perc';
      case 'latency.ms.p99': return '99th Perc';

    }
  }

  renderChart () {
    var ctx = document.getElementById("response_time");
    if (this.state.data.data) {
      var myChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.props.labeller(this),
            datasets: this.getDatasets()
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
            scales: this.props.scaleConfig,
            plugins: {
              tooltip: {
                animate: false,
                caretPadding: 10,
                mode: 'index',
                intersect: false
              },
              crosshair: {
                sync: {
                  enabled: true
                },
                line: {
                  color: '#F66',  // crosshair line color
                  width: 1        // crosshair line width
                },
              },
              legend: {
                position: "top",
                align: "start"
              }
            }
          }
      });

      this.setState({ chart: myChart })
    }
  }

  render () {
    return (
      <canvas id="response_time" width="1000" height="250"></canvas>
    )
  }

}

export default ResponseTimeChart
