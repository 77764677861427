import React, {useEffect, useState} from 'react';

import axios from 'axios'
import _ from "lodash"

import { 
  Card,
  PageHeader,
  Tabs, 
  TabsPanel
} from '@salesforce/design-system-react';

import {MalibuIcon} from '@heroku/react-malibu'

import PropertyValue from "./Utilities/PropertyValue"

import DetailsTab from "./Functions/DetailsTab"
import AddonsTab from "./Functions/AddonsTab"
import BuildsTab from "./Functions/BuildsTab"
import ReleasesTab from "./Functions/ReleasesTab"
import FeaturesTab from "./Functions/FeaturesTab"
import LimitsTab from "./Functions/LimitsTab"
import Links from "./Functions/Links"
import TabLabel from './Utilities/TabLabel';

const FunctionPage = props => {

  const [detailsTabLabel, setDetailsTabLabel] = useState(<TabLabel></TabLabel>)
  const [addonsTabLabel, setAddonsTabLabel] = useState(<TabLabel></TabLabel>)
  const [buildsTabLabel, setBuildsTabLabel] = useState(<TabLabel></TabLabel>)
  const [featuresTabLabel, setFeaturesTabLabel] = useState(<TabLabel></TabLabel>)
  const [limitsTabLabel, setLimitsTabLabel] = useState(<TabLabel></TabLabel>)
  const [releasesTabLabel, setReleasesTabLabel] = useState(<TabLabel></TabLabel>)

  const pageHeaderDetails = [
    {
      label: 'Owner',
      content: <PropertyValue value={_.get(props, "app.owner.email")} clipboard={true} link={`/users/${_.get(props, "app.owner.id")}`} />
    }
    // {
    //   label: 'URL',
    //   content: <PropertyValue value={`https://${_.get(props, "app.name")}.herokuapp.com`} />
    // },
    // { 
    //   label: 'Pipeline', 
    //   content: <PropertyValue value={_.get(props, "pipeline_couplings.pipeline.name")} link={`/pipelines/${_.get(props, "pipeline_couplings.pipeline.id")}`} />
    // },
    // {
    //   label: 'Pipeline Stage',
    //   content: <PropertyValue value={_.get(props, "pipeline_couplings.stage")} />,
    // },
    // {
    //   label: 'Region',
    //   content: <PropertyValue value={_.get(props, "app.region.name")} />
    // },
    // {
    //   label: 'Team',
    //   content: <PropertyValue value={_.get(props, "app.team.name")} />
    // },
    // {
    //   label: 'Space',
    //   content: <PropertyValue value={_.get(props, "app.space.name")} />
    // }
  ]

  function render() {
    return(
      <React.Fragment key={props.title}>

        <div className="slds-grid slds-gutters slds-m-horizontal_medium slds-wrap">
          <div className="slds-col slds-size_1-of-1 slds-m-bottom_large">
            <PageHeader
            details={pageHeaderDetails}
            label="Salesforce Function"
            title={<PropertyValue value={props.app.name} clipboard={true} />}
            icon={<MalibuIcon name="transfer-28" size={32} />}
            variant="record-home"
          />
            </div>

            <div className="slds-col slds-size_3-of-4 slds-m-bottom_large">
              <Tabs variant="scoped">

                <TabsPanel label={detailsTabLabel}>
                  <DetailsTab app_id={_.get(props, "app.id")} setTabLabel={setDetailsTabLabel} />
                </TabsPanel>

                <TabsPanel label={addonsTabLabel}>
                  <AddonsTab app_id={_.get(props, "app.id")} setTabLabel={setAddonsTabLabel} />
                </TabsPanel>

                <TabsPanel label={featuresTabLabel}>
                  <FeaturesTab app_id={_.get(props, "app.id")} setTabLabel={setFeaturesTabLabel} />
                </TabsPanel>

                <TabsPanel label={limitsTabLabel}>
                  <LimitsTab app_id={_.get(props, "app.id")} setTabLabel={setLimitsTabLabel} />
                </TabsPanel>

                <TabsPanel label={buildsTabLabel}>
                  <BuildsTab app_id={_.get(props, "app.id")} setTabLabel={setBuildsTabLabel} />
                </TabsPanel>

                <TabsPanel label={releasesTabLabel}>
                  <ReleasesTab app_id={_.get(props, "app.id")} setTabLabel={setReleasesTabLabel} />
                </TabsPanel>

              </Tabs>

            </div>

            <div className="slds-col slds-size_1-of-4 slds-m-bottom_large">
              <Links app={props.app} />
            </div>
          </div>


        </React.Fragment>
    )
  }

  return render()
}

export default FunctionPage
