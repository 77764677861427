import {Chart, Interaction} from "chart.js"

export default {
  id: 'heroku',

  beforeInit: function(chart) {
    // Set plugin defaults
    // chart.options.plugins.heroku = Object.assign({
    //   mode: 'index'
    // }, chart.options.plugins.heroku)
  },

  beforeEvent: function(chart, e) {
    // console.log("herokuPlugin beforeEvent")
    if (e.type === 'mousemove') {
      chart._mouseX = e.x
    }
  },

  afterDraw: function(chart) {
    // console.log("herokuPlugin afterDraw")
    this.drawTimestamp(chart)
  },

  afterEvent: function(chart, e, options) {
    // console.log('herokuPlugin afterEvent')
    // console.log(options)
    // console.log('parseActiveElements is a ' + typeof(options.parseActiveElements))
    if (typeof(options.parseActiveElements) === 'function') {
      var interactionMode = Interaction.modes.index
      // console.log("interactionmode")
      console.log(interactionMode)

      if (typeof(interactionMode) === 'function') {
        console.log('building items')
        var items = null

        if (e.type !== 'mouseout') {
          console.log('e is not a mouseout')
          console.log(interactionMode(chart, e, {}, false))
          items = interactionMode(chart, e, {axis: "x", intersect: false}, true).map(function(ele) {
            console.log('looping')
            console.log(ele)
            return {
              value: chart.data.datasets[ele._datasetIndex].data[ele._index],
              datasetIndex: ele._datasetIndex,
              index: ele._index
            }
          })
        }

        console.log('about to parse active elements')

        options.parseActiveElements(items)
      }
    }
  },

  drawTimestamp(chart) {
    var meta = chart.getDatasetMeta(0)
    var yScale = chart.scales[meta.yAxisID]
    var xScale = chart.scales[meta.xAxisID]
    var x = chart._mouseX
    var y = Math.max(yScale.getPixelForValue(yScale.max), yScale.getPixelForValue(yScale.min))
    var xPadding = 10
    var yPadding = 10
    var time = xScale.getValueForPixel(x)

    var fontSize = 12
    var label =  "?" // time.format('HH:mm')+" UTC"
    chart.ctx.font = `bold ${fontSize}px "Helvetica Neue", "Helvetica", "Arial", sans-serif`
    var size = chart.ctx.measureText(label)

    chart.ctx.beginPath()
    chart.ctx.rect(x,y,size.width + (xPadding * 2), fontSize + (yPadding * 2))
    chart.ctx.fillStyle = '#f1eef5' //background color
    chart.ctx.fill()
    chart.ctx.fillStyle = '#6f7aba' //font color
    chart.ctx.fillText(label, x+xPadding, y+yPadding)
  }
}