import React, {useState, useEffect} from "react";
import axios from "axios"
import _ from "lodash";
import { 
  Button,
  Card, 
  Icon, 
  IconSettings,
  Search
} from "@salesforce/design-system-react";
import ValidIDNotice from "./AccessCheck/ValidIDNotice";

const AccessCheck = (props) => {

    const [updating, setUpdating] = useState(false)
    const [accessChecks, setAccessChecks] = useState([])
    const [emailInput, setEmailInput] = useState("")
    const [showWarning, setShowWarning] = useState(false)

    useEffect(() => {
      if (props.email) {
        checkAccess(props.email)
      }
    }, [])

    function render() {
      return (
        <Card heading="Customer App Access Check">
          <div className="slds-card__body_inner">
              {accessChecks.map((accessCheck) => {
                  return(
                      <div className="slds-box slds-box_x-small slds-m-bottom_medium" key={accessCheck.user_id_or_email}>
                          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center">
                            <div className="slds-m-right_small">
                              <IconSettings iconPath="/assets/icons">
                                <Icon 
                                  category="utility"
                                  colorVariant="default"
                                  size="xx-small"
                                  name={accessCheck.allowed ? "check" : "error"}
                                  style={{ fill: ( accessCheck.allowed ? "green" : "red" )}}
                                  />
                              </IconSettings>
                            </div>
                            <div>{accessCheck.user_id_or_email}</div>
                            <div className="slds-button slds-col_bump-left" onClick={() => removeFromList(accessCheck.user_id_or_email)}>Dismiss</div>
                          </div>
                      </div>
                  )
              })}

              <ValidIDNotice type="email or UUID" visible={showWarning} setVisible={setShowWarning}/>


              <div className="slds-grid">
                <div className="slds-col slds-size_2-of-3 slds-p-around_none">
                <IconSettings iconPath="/assets/icons">
                  <Search
                    id="access_check_input"
                    placeholder="Check user email/uuid"
                    value={emailInput}
                    onChange={handleInputchange}
                    onSearch={() => {checkAccess(emailInput)}} />
                    </IconSettings>
                </div>
                <div className="slds-col slds-size_1-of-3">
                  { updating && 
                  <Button 
                    label="Checking"
                    disabled={true} />
                  }
                  { !updating && 
                  <Button 
                    label="Check"
                    onClick={() => {checkAccess(emailInput) }} />
                  }
                </div>
              </div>
                  
          </div>
        </Card>
      );
    }

    const removeFromList = identifier => {
      let checks = [...accessChecks];
      accessChecks.forEach((account, i) => {
        if (account.user_id_or_email === identifier) checks.splice(i, 1);
      })
      setAccessChecks(checks);
    }

    const handleInputchange = (e) => {
      setEmailInput(e.target.value)
    }

    const checkAccess = (email) => {
      setUpdating(true)
      const isValidEmail = validateEmail(email);
      const isValidUUID = validateUUID(email)
      if (isValidEmail || isValidUUID) {
        setEmailInput("")
        axios.get(`/apps/${props.app.name}/check_access.json?email=${email}`).then((response) => {
          console.log(response.data)
          setUpdating(false)
          setEmailInput("")
          setShowWarning(false)
          setAccessChecks([...accessChecks, response.data])
         }).catch((error) => {
           console.log('error loading check data')
         })
      } else {
        if (!isValidEmail) setShowWarning(true);
        setUpdating(false)
      }
    }

  

  return render();
};

export function validateEmail(identifier) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = re.test(String(identifier).toLowerCase());
  if (!isValid) console.log(`${identifier} is not valid email address`)
  return isValid;
}

export function validateUUID(identifier) {
  const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const isValid = re.test(String(identifier).toLowerCase());
  if (!isValid) console.log(`${identifier} is not valid UUID`)
  return re.test(String(identifier).toLowerCase());
}

export default AccessCheck;
