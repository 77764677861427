import React from 'react';
import _ from "lodash"

import { 
  Card,
  Icon,
  IconSettings
} from '@salesforce/design-system-react';

import {MalibuIcon} from '@heroku/react-malibu'

const ProfilePopover = props => {

  function render() {
    return(
        <div>
            <dl>
                <dt>Email</dt>
                <dd>{props.current_user.email}</dd>
                <dt>Role</dt>
                <dd>{props.current_user.role}</dd>
            </dl>
        </div>
    )
  }

  return render()
}

export default ProfilePopover