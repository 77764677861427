import React from "react"
import PropTypes from "prop-types"

import axios from "axios"
import moment from "moment"

import EventsChart from "./EventsChart"
import ThroughputChart from "./ThroughputChart"
import ResponseTimeChart from "./ResponseTimeChart"
import MemoryUsageChart from "./MemoryUsageChart"
import LoadChart from "./LoadChart"

class Metrics extends React.Component {

  getLabels (_this) {
    var labels = []
    var time = moment().subtract(2, 'days')
    labels.push(time.toDate());
    if (_this.state.data.data && Object.keys(_this.state.data.data).length > 0) {
      _this.state.data.data[Object.keys(_this.state.data.data)[0]].forEach(function(e) {
        time = time.add(10, 'minutes');
        let minute = Math.floor(time.minute() / 10) * 10
        labels.push(time.minute(minute).second(0).toDate());
      });
      labels.pop();
    }
    return labels;
  }

  scaleConfig () {
    return {
        y: {
          ticks: {
              beginAtZero:true
          },
          afterFit: function(scaleInstance) {
            scaleInstance.width = 50
          }
        },
        x: {
          barPercentage: 1.0,
          type: 'time',
          gridLines: {
            drawOnChartArea: false,
            lineWidth: 2,
            tickMarkLength: 8
          },
          ticks: {
            padding: 3
          },
          time: {
              displayFormats: {
                hour: 'MMM D HH:mm'
              }
          }
        }
    }
  }

  render () {
    return (
      <div className='metrics'>
        <h2 className="slds-text-title_caps slds-m-top_x-large">Events</h2>
        <EventsChart app_id={this.props.app_id}
                    labeller={this.getLabels}
                    scaleConfig={this.scaleConfig()}
                    />

        <h2 className="slds-text-title_caps slds-m-top_x-large">Throughput (requests/10 min)</h2>
        <ThroughputChart app_id={this.props.app_id}
                    labeller={this.getLabels}
                    scaleConfig={this.scaleConfig()}
                    />

        <h2 className="slds-text-title_caps slds-m-top_x-large">Response Time</h2>
        <ResponseTimeChart app_id={this.props.app_id}
                    labeller={this.getLabels}
                    scaleConfig={this.scaleConfig()}
                    /> 

        <h2 className="slds-text-title_caps slds-m-top_x-large">Dyno Load</h2>
        <LoadChart app_id={this.props.app_id}
                    labeller={this.getLabels}
                    scaleConfig={this.scaleConfig()}
                    />

        <h2 className="slds-text-title_caps slds-m-top_x-large">Memory Usage (Mb)</h2>
        <MemoryUsageChart app_id={this.props.app_id}
                    labeller={this.getLabels}
                    scaleConfig={this.scaleConfig()}
                    />

      </div>
    )
  }
}

export default Metrics
