import React from "react";

import { Spinner } from "@salesforce/design-system-react";

const TabLoading = (props) => {
  function render() {
    return (
      <div style={{ position: "relative", height: "5rem" }}>
        <Spinner
          size="small"
          variant="base"
          assistiveText={{ label: "Main Frame Loading..." }}
        />
      </div>
    );
  }

  return render();
};

export default TabLoading;