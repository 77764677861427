import React, { useState } from 'react';

import { 
  Button,
  Icon,
  IconSettings
} from '@salesforce/design-system-react';

const CommandOutput = props => {

  const [showCommand, setShowCommand] = useState(false)
  const [copyCommandIcon, setCopyCommandIcon] = useState("copy")
  const [copyOutputIcon, setCopyOutputIcon] = useState("copy")

  function handleCopyCommandToClipboard(e) {
    navigator.clipboard.writeText(props.command.command)
    setCopyCommandIcon("check")
    setTimeout(() => {
      setCopyCommandIcon("copy")
    }, 3000);
  }

  function handleCopyOutputToClipboard(e) {
    navigator.clipboard.writeText(props.command.stdout)
    setCopyOutputIcon("check")
    setTimeout(() => {
      setCopyOutputIcon("copy")
    }, 3000);
  }

  function render() {
    return(
      <React.Fragment>
      <div className="site-command slds-m-bottom_small">
          <div className="site-command__header" onClick={() => {setShowCommand(!showCommand)}}>
            {props.command.command && <div className="site-command__header-item site-command__command">{props.command.command}</div>}
            {props.command.result && <div className="site-command__header-item site-command__result">{props.command.result}</div>}
            {props.command.info && <div className="site-command__header-item site-command__info">{props.command.info}</div>}
          </div>
          { !!showCommand && 
            <React.Fragment>
              <div className="site-command__actions">
              <IconSettings iconPath="/assets/icons">
                <Button
                  iconCategory="utility"
                  iconName={copyCommandIcon}
                  iconPosition="left"
                  label="Copy Command"
                  onClick={handleCopyCommandToClipboard}
                />
                <Button
                  iconCategory="utility"
                  iconName={copyOutputIcon}
                  iconPosition="left"
                  label="Copy Output"
                  onClick={handleCopyOutputToClipboard}
                />
              </IconSettings>
              </div>

              { props.command.stdout && <div className="site-command__stdout">
                <h3 class="slds-text-title_caps slds-border_bottom slds-p-bottom_x-small slds-m-bottom_xx-small slds-m-top_large">STDOUT Standard Out</h3>
                <code><pre>{props.command.stdout}</pre></code>
              </div>}

              { props.command.stderr && <div className="site-command__stderr">
              <h3 class="slds-text-title_caps slds-border_bottom slds-p-bottom_x-small slds-m-bottom_xx-small slds-m-top_large">STDERR Standard Error</h3>
                <code><pre>{props.command.stderr}</pre></code>
              </div>}
            </React.Fragment>
          }
      </div>
      </React.Fragment>
    )
  }

  return render()
}

export default CommandOutput