import React from 'react'

const Warning = props => {

  function render() {
    if (props.message === null || props.message === undefined) {
      return (
        props.children
      )
    } else {
      return(
        <div className="hs-property-warning">
          {props.children}
          <span className="hs-text-color_very-weak slds-p-left_small">{props.message}</span>
        </div>
      )
    }
  }

  return render()
}

export default Warning
