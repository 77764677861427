import React, {useEffect, useState} from 'react';
import _ from "lodash"
import axios from 'axios';

import Warnings from '../Utilities/Warnings';
import DefinitionItem from "../Utilities/DefinitionItem"
import PropertyValue from "../Utilities/PropertyValue"
import TabLabel from '../Utilities/TabLabel';
import TabLoading from '../Utilities/TabLoading';

const DetailsTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Details</TabLabel>)
    axios.get(`/users/${props.user_id}.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Details</TabLabel>)
    }).catch((error) => {
      console.log('error loading user data')
    })
  }, [])

  function render() {
    if (data !== null) {
      return (
        <React.Fragment key="app-details">

        <Warnings warnings={data.warnings} />

        <h2 className="hs-section-heading">Details</h2>
        <dl className="slds-dl_horizontal">
          <DefinitionItem title="UUID">
            <PropertyValue value={data.user.id} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Email">
            <PropertyValue value={data.user.email} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Name">
            <PropertyValue value={data.user.name} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Beta User">
            <PropertyValue value={data.user.beta_user} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Verified">
            <PropertyValue value={data.user.verified} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Suspended At">
            <PropertyValue value={data.user.suspended_at} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Delinquent At">
            <PropertyValue value={data.user.delinquent_at} clipboard={true} />
          </DefinitionItem>
        </dl>

        <h2 className="hs-section-heading">Team / Login details </h2>
        <dl className="slds-dl_horizontal">
          <DefinitionItem title="Last Login">
            <PropertyValue value={data.user.last_login} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="2FA">
            <PropertyValue value={data.user.two_factor_authentication} />
          </DefinitionItem>
          <DefinitionItem title="SMS">
            <PropertyValue value={data.user.sms} />
          </DefinitionItem>
          <DefinitionItem title="Default Organization">
            <PropertyValue value={_.get(data, "user.default_organization.name")} link={data.user.default_organization && `/teams/${data.user.default_organization.id}`} />
          </DefinitionItem>
          <DefinitionItem title="Default Team">
            <PropertyValue value={_.get(data, "user.default_team.name")} link={data.user.default_team && `/teams/${data.user.default_team.id}`} />
          </DefinitionItem>
          <DefinitionItem title="Federated">
            <PropertyValue value={data.user.federated} />
          </DefinitionItem>
          <DefinitionItem title="Identity PropertyValue">
            <PropertyValue value={data.user.idetity_provider} />
          </DefinitionItem>
        </dl>

        <h2 className="hs-section-heading">Metadata</h2>
        <dl className="slds-dl_horizontal">
          <DefinitionItem title="Created At">
            <PropertyValue value={data.user.created_at} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Updated At">
            <PropertyValue value={data.user.updated_at} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Allow Tracking">
            <PropertyValue value={data.user.allow_tracking} />
          </DefinitionItem>
          <DefinitionItem title="Country of Residence">
            <PropertyValue value={data.user.country_of_residence} />
          </DefinitionItem>
          <DefinitionItem title="Acknowledged MSA">
            <PropertyValue value={data.user.acknowledged_msa} />
          </DefinitionItem>
          <DefinitionItem title="Acknowledged MSA At">
            <PropertyValue value={data.user.acknowledged_msa_at} />
          </DefinitionItem>
          <DefinitionItem title="Agreed to Italian Customer Terms">
            <PropertyValue value={data.user.italian_customer_terms} />
          </DefinitionItem>
          <DefinitionItem title="Agreed to Italian Partner Terms">
            <PropertyValue value={data.user.italian_partner_term} />
          </DefinitionItem>
        </dl>

      </React.Fragment>
      )
    } else {
      return(<TabLoading />)
    }
  }

  return render()
}

export default DetailsTab
