import React from 'react';

import _ from "lodash"

import { 
  Card,
  Icon,
  IconSettings
} from '@salesforce/design-system-react';

import {MalibuIcon} from '@heroku/react-malibu'

const HelpPopover = props => {

  function render() {
    return(
        <div>
            <h2 className="slds-text-heading_small" id="help-heading">
            Help and Training
            </h2>

            <p>
            <MalibuIcon name="service-slack-16" size={20} />
            <a href="https://heroku.slack.com/archives/C1N712KFH" target="_blank">Heroku Support on Slack</a>
            </p>
        </div>
    )
  }

  return render()
}

export default HelpPopover