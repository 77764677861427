import React from 'react';

const DefinitionItem = props => {

  function render() {
    return(
      <React.Fragment key={props.title}>
        <dt className="slds-dl_horizontal__label slds-text-color_weak slds-truncate slds-p-top_xxx-small" title={props.title}>{props.title}</dt>
        <dd className="slds-dl_horizontal__detail slds-truncate slds-p-top_xxx-small">{props.children}</dd>
      </React.Fragment>
    )
  }

  return render()
}

export default DefinitionItem
