import _ from "lodash";
import React, {useEffect, useState} from 'react';
import axios from "axios";

import Warnings from "../Utilities/Warnings"
import CommandOutput from "../Utilities/CommandOutput"
import DefinitionItem from "../Utilities/DefinitionItem";
import PropertyValue from "../Utilities/PropertyValue";
import TabLabel from "../Utilities/TabLabel";
import TabLoading from "../Utilities/TabLoading";

const DNSTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>DNS</TabLabel>)
    axios.get(`/apps/${props.app_id}/dns.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>DNS ({response.data.domains.length})</TabLabel>)
    }).catch((error) => {
      console.log('error loading dns data')
      console.log(error)
    })
  }, [])


  function render() {
    if (data) {
      return(
        <React.Fragment key="app-dns">
          <Warnings warnings={data.warnings} />

              {data.domains.map((domain) => {
                return(
                  <div key={domain.id} className="slds-m-bottom_xx-large">

                    <h2 className="slds-text-title_caps slds-border_bottom slds-p-bottom_x-small slds-m-bottom_xx-small slds-m-top_large">{domain.hostname}</h2>
                    <dl className="slds-dl_horizontal slds-m-bottom_small">
                      <DefinitionItem title="Host Name">
                        <PropertyValue value={`https://${domain.hostname}`} clipboard={true} />
                      </DefinitionItem>
                      <DefinitionItem title="CNAME Target">
                        <PropertyValue value={domain.cname} clipboard={true} />
                      </DefinitionItem>
                      <DefinitionItem title="ACM Status">
                        <PropertyValue value={domain.acm_status} /> &nbsp;
                        <PropertyValue value={domain.acm_status_reason} />
                      </DefinitionItem>
                      <DefinitionItem title="SNI Endpoint">
                        <PropertyValue value={_.get(domain, "sni_endpoint.name")} />
                      </DefinitionItem>
                    </dl>

                    {domain.dig && <CommandOutput command={domain.dig} />}
                    {domain.curl_http && <CommandOutput command={domain.curl_http} />}
                    {domain.curl_https && <CommandOutput command={domain.curl_https} />}
                  </div>
                )
              })}

      </React.Fragment>
      )
    } else {
      return(<TabLoading />)
    }
  }

  return render()
}

export default DNSTab
