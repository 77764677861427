import React, {useEffect, useState} from 'react'

import axios from 'axios'
import _ from "lodash"

import { 
  IconSettings, 
  PageHeader,
  Popover,
  Tabs, 
  TabsPanel
} from '@salesforce/design-system-react'

import {MalibuIcon} from '@heroku/react-malibu'

//import DefinitionItem from "./Utilities/DefinitionItem"
import PropertyValue from "./Utilities/PropertyValue"

import DetailsTab from "./Teams/DetailsTab"
import AppsTab from "./Teams/AppsTab"
import LimitsTab from './Teams/LimitsTab'
// import MembersTab from "./Teams/MembersTab"
import TabLabel from './Utilities/TabLabel'

const TeamPage = props => {

  const [detailsTabLabel, setDetailsTabLabel] = useState(<TabLabel></TabLabel>)
  const [appsTabLabel, setAppsTabLabel] = useState(<TabLabel></TabLabel>)
  const [limitsTabLabel, setLimitsTabLabel] = useState(<TabLabel></TabLabel>)
  // const [membersTabLabel, setMembersTabLabel] = useState(<TabLabel></TabLabel>)

  const pageHeaderDetails = [
    {
      label: 'Email',
      content: <PropertyValue value={_.get(props, "team.email")} clipboard={true} />
    },
    {
      label: 'UUID',
      content: <PropertyValue value={_.get(props, "team.id")} clipboard={true} />
    }
    // { 
    //   label: 'Verified', 
    //   content: <PropertyValue value={_.get(props, "user.verified_at")} />
    // },
    // {
    //   label: 'Delinquent',
    //   content: <PropertyValue value={_.get(props, "user.delinquent_at")} />,
    // },
    // {
    //   label: 'Suspended',
    //   content: <PropertyValue value={_.get(props, "user.suspended_at")} />
    // },
    // {
    //   label: 'Last Login',
    //   content: <PropertyValue value={_.get(props, "user.last_login")} />
    // },
  ]

  function render() {
    return(
      <React.Fragment key={props.title}>

        <div className="slds-grid slds-gutters slds-m-horizontal_medium slds-wrap">
          <div className="slds-col slds-size_1-of-1 slds-m-bottom_large">
            <PageHeader
            details={pageHeaderDetails}
            label="Team"
            title={props.team.name}
            icon={<MalibuIcon name="team-28" size={32} />}
            variant="record-home"
          />
            </div>

            <div className="slds-col slds-size_3-of-4 slds-m-bottom_large">
              <Tabs variant="scoped">
                <TabsPanel label={detailsTabLabel}>
                  <DetailsTab team_id={props.team.id} setTabLabel={setDetailsTabLabel} />
                </TabsPanel>

                <TabsPanel label={appsTabLabel} >
                  <AppsTab team_id={props.team.id} setTabLabel={setAppsTabLabel}/>
                </TabsPanel>

                <TabsPanel label={limitsTabLabel} >
                  <LimitsTab team_id={props.team.id} setTabLabel={setLimitsTabLabel}/>
                </TabsPanel>
              </Tabs>

            </div>

            <div className="slds-col slds-size_1-of-4">

            </div>
          </div>


        </React.Fragment>
    )
  }

  return render()
}

export default TeamPage
