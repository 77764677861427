import React, {useEffect, useState} from 'react';
import _ from "lodash"
import axios from 'axios';

import Warnings from '../Utilities/Warnings';
import DefinitionItem from "../Utilities/DefinitionItem"
import PropertyValue from "../Utilities/PropertyValue"
import TabLabel from '../Utilities/TabLabel';
import TabLoading from '../Utilities/TabLoading';

const MembersTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Details</TabLabel>)
    axios.get(`/teams/${props.team_id}/members.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Members</TabLabel>)
    }).catch((error) => {
      console.log('error loading team data')
    })
  }, [])

  function render() {
    if (data !== null) {
      return (
        <React.Fragment key="app-details">

        <Warnings warnings={data.warnings} />

        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {data.members.map(member => {
              return(<tr>
                <td>{member.name}</td>
                <td>{member.email}</td>
                <td>{member.role}</td>
              </tr>)
            })}
          </tbody>
        </table>

      </React.Fragment>
      )
    } else {
      return(<TabLoading />)
    }
  }

  return render()
}

export default MembersTab
