import React from 'react';

import _ from "lodash"

import { 
  Card,
  Icon,
  IconSettings
} from '@salesforce/design-system-react';

const Links = props => {

  function render() {
    return(
        <IconSettings iconPath="/assets/icons">
            <Card heading="External Tools and Dashboards">
                <div className="slds-card__body_inner">
                    <ul>
                        <li className="slds-m-top_small">
                            <a href={`https://dashboard.heroku.com/assume?email=${props.user.email}&reason=support-console`} target="_blank" rel="noopener noreferrer" data-confirm="Please ensure you have permission from the customer before assuming ID on Dashboard">
                            Heroku Dashboard (Assuming ID)&nbsp;<Icon category="utility" colorVariant="light" size="xx-small" name="new_window" />
                            </a>
                        </li>
                        <li className="slds-m-top_small">
                            <a href={`https://splunk.herokai.com/app/search/stevos_api_rate_limit_investigator_beta?form.agent=*&form.email=${props.user.email}`} target="_blank" rel="noopener noreferrer">
                            API Rate Limit Investigator&nbsp;<Icon category="utility" colorVariant="light" size="xx-small" name="new_window" />
                            </a>
                        </li>
                    </ul>
                </div>
            </Card>
        </IconSettings>
    )
  }

  return render()
}

export default Links