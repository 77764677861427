// import PropTypes from 'prop-types';
import React from 'react';

import PropertyValue from './PropertyValue';

const PropertyValueArray = props => {

  function render() {
    return (
      <React.Fragment>
        {props.values.map((value) => {
            return <PropertyValue value={value} />
        })}
      </React.Fragment>
    );
  }

  return render()
}

export default PropertyValueArray