import _ from "lodash";
import React, {useState, useEffect} from 'react';
import axios from "axios";

import PropertyValue from "../Utilities/PropertyValue"
import Warnings from "../Utilities/Warnings"
import TabLabel from "../Utilities/TabLabel";
import TabLoading from "../Utilities/TabLoading"

const LimitsTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Limits</TabLabel>)
    axios.get(`/users/${props.team_id}/limits.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Limits ({response.data.limits.length})</TabLabel>)
    }).catch((error) => {
      console.log('error loading limits data')
    })
  }, [])

  function render() {
    if (data) {
      return(
        <React.Fragment key="user-limits">
  
          <Warnings warnings={data.warnings} />
  
          <h2 className="slds-text-heading_medium slds-m-top_small slds-m-bottom_small">Limits</h2>

          <table className="slds-table slds-table_bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {data.limits.map((limit) => {
                return(
                  <tr key={`user-limits-${limit.name}`}>
                    <td><PropertyValue value={limit.name} clipboard={true} /></td>
                    <td><PropertyValue value={limit.value} clipboard={true} /></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
  
        </React.Fragment>
      ) 
    } else { 
      return(<TabLoading />)
    }
  }

  return render()
}

export default LimitsTab
