import React, {useEffect, useState} from 'react';
import _ from "lodash"
import axios from 'axios';

import Warnings from '../Utilities/Warnings';
import DefinitionItem from "../Utilities/DefinitionItem"
import PropertyValue from "../Utilities/PropertyValue"
import TabLabel from '../Utilities/TabLabel';
import TabLoading from '../Utilities/TabLoading';

const DetailsTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Details</TabLabel>)
    axios.get(`/teams/${props.team_id}/details.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Details</TabLabel>)
    }).catch((error) => {
      console.log('error loading team data')
    })
  }, [])

  function render() {
    if (data !== null) {
      return (
        <React.Fragment key="app-details">

        <Warnings warnings={data.warnings} />

        <h2 className="hs-section-heading">Details</h2>
        <dl className="slds-dl_horizontal">
          <DefinitionItem title="UUID">
            <PropertyValue value={data.team.id} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Email">
            <PropertyValue value={data.team.email} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Name">
            <PropertyValue value={data.team.name} clipboard={true} />
          </DefinitionItem>
          {/* <DefinitionItem title="Beta team">
            <PropertyValue value={data.team.beta_team} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Verified">
            <PropertyValue value={data.team.verified} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Suspended At">
            <PropertyValue value={data.team.suspended_at} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Delinquent At">
            <PropertyValue value={data.team.delinquent_at} clipboard={true} />
          </DefinitionItem> */}
        </dl>

        {/* <h2 className="hs-section-heading">Team / Login details </h2>
        <dl className="slds-dl_horizontal">
          <DefinitionItem title="Last Login">
            <PropertyValue value={data.team.last_login} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="2FA">
            <PropertyValue value={data.team.two_factor_authentication} />
          </DefinitionItem>
          <DefinitionItem title="SMS">
            <PropertyValue value={data.team.sms} />
          </DefinitionItem>
          <DefinitionItem title="Default Organization">
            <PropertyValue value={data.team.default_organization} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Default Team">
            <PropertyValue value={data.team.default_team} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Federated">
            <PropertyValue value={data.team.federated} />
          </DefinitionItem>
          <DefinitionItem title="Identity PropertyValue">
            <PropertyValue value={data.team.idetity_provider} />
          </DefinitionItem>
        </dl> */}

        <h2 className="hs-section-heading">Metadata</h2>
        <dl className="slds-dl_horizontal">
          <DefinitionItem title="Created At">
            <PropertyValue value={data.team.created_at} clipboard={true} />
          </DefinitionItem>
          <DefinitionItem title="Updated At">
            <PropertyValue value={data.team.updated_at} clipboard={true} />
          </DefinitionItem>
        </dl>

      </React.Fragment>
      )
    } else {
      return(<TabLoading />)
    }
  }

  return render()
}

export default DetailsTab
