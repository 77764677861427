import React, {useEffect, useState} from 'react';
import axios from 'axios';

import PropertyValue from "../Utilities/PropertyValue"
import Warnings from "../Utilities/Warnings"
import TabLabel from '../Utilities/TabLabel';
import TabLoading from '../Utilities/TabLoading';

const AddonsTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Add-ons</TabLabel>)
    axios.get(`/functions/${props.app_id}/addons.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Add-ons ({response.data.addons.length})</TabLabel>)
    }).catch((error) => {
      console.log('error loading add-on data')
    })
  }, [])

  function render() {
    if (data) {
      return(
        <React.Fragment key="function-addons">

          <Warnings warnings={data.warnings} />

          <h2 className="slds-text-title_caps slds-m-top_large slds-m-bottom_small">Add-ons</h2>

          <table className="slds-table slds-table_bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Plan</th>
                <th>Resource Name</th>
                <th>Billing App</th>
              </tr>
            </thead>
            <tbody>
              {data.addons.map((addon) => {
                return(
                  <tr key={addon.id}>
                    <td>{addon.addon_service.name}</td>
                    <td>{addon.plan.name}</td>
                    <td><PropertyValue value={addon.name} clipboard={true} /></td>
                    <td className="slds-truncate slds-cell-wrap"><PropertyValue value={addon.billing_entity.name} link={`/apps/${addon.billing_entity.name}`} /></td>
                  </tr>
                )
              })}
                </tbody>
              </table>

            <h2 className="slds-text-title_caps slds-m-top_large slds-m-bottom_small">Logs and Drains</h2>
            { !_.isEmpty(data.drains) &&
            <table className="slds-table slds-table_bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Addon</th>
                  <th>URL</th>
                </tr>
              </thead>

              <tbody>
                {data.drains.map((drain) => {
                  return(
                    <tr key={drain.id}>
                      <td>{drain.id}</td>
                      <td>{_.get(drain, "addon.name")}</td>
                      <td  className="slds-truncate slds-cell-wrap">{drain.url}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            }
            {
              _.isEmpty(data.drains) &&
              <p>There are no drains setup for this app</p>
            }

            </React.Fragment>
      ) 
    } else { 
      return(<TabLoading />)
    }
  }

  return render()
}

export default AddonsTab
