import React, {useEffect, useState} from 'react';
import _ from "lodash"
import axios from 'axios';

import TabLabel from '../Utilities/TabLabel';
import TabLoading from '../Utilities/TabLoading';

const AppsTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Apps</TabLabel>)
    axios.get(`/teams/${props.team_id}/apps.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Apps ({response.data.apps.length})</TabLabel>)
    }).catch((error) => {
      console.log('error loading user data')
    })
  }, [])

  function render() {
    if (data) {
      return (
        <React.Fragment key="app-details">
          <h2 className="slds-text-heading_medium">Apps</h2>

          <table className="slds-table slds-table_cell-buffer slds-table_bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {data.apps.map((app) => {
                return(
                <tr key={app.id}>
                  <td><a href={`/apps/${app.name}`}>{app.name}</a></td>
                  <td></td>
                </tr>
                )
              })}
              </tbody>
            </table>


          </React.Fragment>
      )
    } else {
      return(<TabLoading />)
    }
  }

  return render()
}

export default AppsTab
