import React from 'react'

const TabLabel = props => {

    // props.isLoading
    // props.hasWarnings

    function colourClass() {

        if (props.isLoading) {
            return "hs-text-color_very-weak"
        } else {
            if (props.hasWarnings) {
                return "hs-text-color_warning"
            } else {
                return "slds-text-color_default"
            }
        }
    }

    function render() {
        return (
            <span className={colourClass()}>
                {props.children}
            </span>
        )
    }

    return render()
}

export default TabLabel