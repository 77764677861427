import React from 'react'
import _ from "lodash"

const Warnings = props => {

  function render() {
    if (props.warnings === null || props.warnings === undefined || _.isEmpty(props.warnings)) {
      return (
        <React.Fragment>
        </React.Fragment>
      )
    } else {
      return(
        <div className="hs-warnings">
          <h2 className="slds-text-heading_small">Warnings</h2>
            {Object.entries(props.warnings).map(([attr, message]) => {
              return <p>{message}</p>
            })}
        </div>
      )
    }
  }

  return render()
}

export default Warnings
