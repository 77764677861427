import React, {useEffect, useState} from 'react';

import axios from 'axios'
import _ from "lodash"

import { 
  Card,
  PageHeader,
  Tabs, 
  TabsPanel
} from '@salesforce/design-system-react';

import {MalibuIcon} from '@heroku/react-malibu'

import PropertyValue from "./Utilities/PropertyValue"

import DetailsTab from "./Spaces/DetailsTab"
import LimitsTab from "./Spaces/LimitsTab"
import TabLabel from './Utilities/TabLabel';

const SpacePage = props => {

  const [detailsTabLabel, setDetailsTabLabel] = useState(<TabLabel></TabLabel>)
  const [limitsTabLabel, setLimitsTabLabel] = useState(<TabLabel></TabLabel>)

  const pageHeaderDetails = [
    {
      label: 'shield',
      content: <PropertyValue value={props.space.shield} />
    },
    {
      label: "State",
      content: props.space.state
    },
    {
      label: "Team",
      content: <PropertyValue value={props.space.team.name} link={`/teams/${props.space.team.id}`} clipboard={true} />
    },
    {
      label: "Region",
      content: <PropertyValue value={props.space.region.name} />
    }
  ]

  function render() {
    return(
      <React.Fragment key={props.title}>

        <div className="slds-grid slds-gutters slds-m-horizontal_medium slds-wrap">
          <div className="slds-col slds-size_1-of-1 slds-m-bottom_large">
            <PageHeader
              details={pageHeaderDetails}
              label="Heroku Sapce"
              title={<PropertyValue value={props.space.name} clipboard={true} />}
              icon={<MalibuIcon name="space-28" size={32} />}
              variant="record-home"
            />
            </div>

            <div className="slds-col slds-size_3-of-4 slds-m-bottom_large">
              <Tabs variant="scoped">

                <TabsPanel label={detailsTabLabel}>
                  <DetailsTab space_id={_.get(props, "space.id")} setTabLabel={setDetailsTabLabel} />
                </TabsPanel>

                {/* <TabsPanel label={limitsTabLabel}>
                  <LimitsTab space_id={_.get(props, "app.id")} setTabLabel={setLimitsTabLabel} />
                </TabsPanel> */}

              </Tabs>

            </div>

            <div className="slds-col slds-size_1-of-4 slds-m-bottom_large">

            </div>
          </div>


        </React.Fragment>
    )
  }

  return render()
}

export default SpacePage