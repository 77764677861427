import React, {useEffect, useState} from 'react';
import axios from 'axios';

import PropertyValue from "../Utilities/PropertyValue"
import Warnings from "../Utilities/Warnings"
import TabLabel from '../Utilities/TabLabel';
import TabLoading from '../Utilities/TabLoading';

const ReleasesTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Releases</TabLabel>)
    axios.get(`/functions/${props.app_id}/releases.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Releases</TabLabel>)
    }).catch((error) => {
      console.log('error loading releases')
    })
  }, [])

  function render() {
    if (data !== null) {
      return(
        <React.Fragment key="function-releases">

          <Warnings warnings={data.warnings} />

          <table className="slds-table slds-table_cell-buffer slds-table_bordered">
            <thead>
              <tr>
                <th>Version</th>
                <th>Description</th>
                <th>Created</th>
                <th>Status</th>
                <th>Output</th>
              </tr>
            </thead>
            <tbody>
              {data.releases.map((release) => {
                return(
                  <tr key={release.id} style={{"fontWeight" : (release.current && "bold")}}>
                    <td><PropertyValue value={release.version} /></td>
                    <td className="slds-cell-wrap"><PropertyValue value={(release.current && "[CURRENT] " || "") + release.description} /></td>
                    <td><PropertyValue value={release.created_at} /></td>
                    <td><PropertyValue value={release.status} /></td>
                    <td><a href={`/apps/${release.app.id}/releases/${release.id}/output.txt`} target="_blank">Output</a></td>
                  </tr>
                )
              })}
                </tbody>
              </table>

            </React.Fragment>
      ) 
    } else { 
      return(<TabLoading />)
    }
  }

  return render()
}

export default ReleasesTab
