import React from 'react';

import { 
  Combobox,
  Dropdown,
  Icon,
  IconSettings, 
  GlobalHeader,
  GlobalHeaderButton,
  GlobalHeaderSearch,
  GlobalHeaderHelp,
  GlobalHeaderSetup,
  GlobalHeaderProfile,
  Popover
} from '@salesforce/design-system-react';

import {MalibuIcon} from '@heroku/react-malibu'

import HelpPopover from './SiteGlobalHeader/HelpPopover';
import ProfilePopover from './SiteGlobalHeader/ProfilePopover';

const SiteGlobalHeader = props => {
  function history() {
    return props.history.map((item) => {
      return {
        id: item.url, 
        icon: iconForType(item.type),
        label: item.name, 
        subTitle: item.type, 
        url: item.url
      }
    })
  }

  function iconForType(x) {
    switch (x) {
      case "Heroku Application":
        return <MalibuIcon name="app-28" size={32} />
        break
      case "Heroku User":
        return <MalibuIcon name="user-28" size={32} />
        break
      case "Heroku Team":
        return <MalibuIcon name="team-28" size={32} />
        break
      case "Heroku Space":
        return <MalibuIcon name="space-28" size={32} />
        break
      case "Salesforce Function":
        // TOOD: Find the correct Icon for Functions
        return <MalibuIcon name="transfer-28" size={32} />
        break
    }
    return <MalibuIcon name="warning-badge-28" size={32} />
  }

  function searchPlaceholderText() {
    if (props.query) {
      return props.query
    } else {
      return "Search for Apps ..."
    }
  }

  function render() {
    return(
      <IconSettings iconPath="/assets/icons">

        <GlobalHeader logoSrc={window.const.global_logo_path}>

          <GlobalHeaderSearch
            combobox={
              <Combobox 
                assistiveText={{ label: 'Search' }} 
                events={{
                  onSelect: (e, data) => {
                    window.location.replace(data.selection[0].url)
                  },
                  onSubmit: (e) => {
                    window.location.replace(`/search?query=${e.target.value}`)
                  }
                }}
                id="header-search" 
                defaultValue={props.query}
                labels={{ placeholder: "Search for Apps, Users, Spaces, Pipelines ..."}} 
                options={history()}
              />
            }
          />

          <GlobalHeaderButton 
          onClick={(e) => {
            window.location.replace("/")
          }}
          >
            <Icon category="utility" name="home" size="small" />
          </GlobalHeaderButton>

          <GlobalHeaderHelp
            popover={
              <Popover
                ariaLabelledby="help-heading"
                body={<HelpPopover />}
              />
            }
          />

          <GlobalHeaderSetup
            dropdown={
              <Dropdown
                id="header-setup-dropdown-id"
                onSelect={(option) => {
                  window.location.replace(option.href)
                }}
                options={[
                  { id: 'AdminUsers', label: 'Users', href: "/admin/users" }
                ]}
              />
            }
          />

          <GlobalHeaderProfile
            popover={
              <Popover 
                id="header-profile-popover-id"
                body={<ProfilePopover current_user={props.current_user} />}
              />
            }
            avatar={props.current_user.gravatar_url}
            userName={props.current_user.name}
          />

        </GlobalHeader>

      </IconSettings>
    )
  }

  return render()
}

export default SiteGlobalHeader
