import React from 'react';

import _ from "lodash"

import { 
  Card,
  Icon,
  IconSettings
} from '@salesforce/design-system-react';

// import {MalibuIcon} from '@heroku/react-malibu'

// import DefinitionItem from "./Utilities/DefinitionItem"
// import PropertyValue from "./Utilities/PropertyValue"

const Links = props => {

  function renderLink(label, url) {
    return (
      <li className="slds-m-top_small">
        <a href={url} target="_blank" rel="noopener noreferrer">
          {label}&nbsp;
          <IconSettings iconPath="/assets/icons">
            <Icon category="utility" colorVariant="light" size="xx-small" name="new_window" />
        </IconSettings>
        </a>
      </li>
    )
  }

  function render() {
    return(
      <Card heading="External Tools and Dashboards">

        <div className="slds-card__body_inner">
        <ul>
          {renderLink("Postgres/Redis Support (OKI)", `https://oki.herokai.com/search/${_.get(props, 'app.name')}`)}
          {renderLink("Connect Support", `https://hc-support.herokai.com/search/${_.get(props, 'app.name')}`)}

          {renderLink("ION US", `https://ion.heroku.com`)}
          {renderLink("ION EU", `https://ion-eu-west-1-a.heroku.com`)}

          {renderLink("Runtime Ops", `https://runtime-ops.herokai.com/apps/${_.get(props, 'app.id')}`)}
          {renderLink("Add-ons Portal", "https://addons-next.heroku.com/addons")}
        </ul>


        {
          Object.entries({
            "60 mins": "1h",
            "4 hours": "4h",
            "12 hours": "12h",
            "24 hours": "24h"
          }).forEach(([key, value]) => {
            <a href={`https://splunk.herokai.com/app/search/app_performance_information_for_dogwood_with_dogwood_router?earliest=-#{value}%40h&amp;latest=now&amp;form.appId=${_.get(props, "app.id")}`} target="_blank" rel="noopener noreferrer">{key}</a>
          })
        }

        {
          Object.entries({
            "60 mins": "1h",
            "4 hours": "4h",
            "12 hours": "12h",
            "24 hours": "24h"
          }).forEach(([key, value]) => {
            <a href={`https://splunk.herokai.com/app/search/application_performance_investigator?form.ps=web.*&amp;earliest=-#{params}%40h&amp;latest=now&amp;form.appId=${_.get(props, "app.id")}`} target="_blank" rel="noopener noreferrer">{key}</a>
          })
        }

        <ul>
          {renderLink("DDoS Investigator", `https://splunk.herokai.com/app/search/ddos_investigator_private_spaces_app?earliest=-60m%40m&amp;latest=now&amp;form.agent=*&amp;form.path=*&amp;form.fwd=*&amp;form.appId=${_.get(props, "app.id")}`)}
          {renderLink("ACM Investigator", `https://splunk.herokai.com/app/search/acm_investigator?earliest=-3d%40d&amp;latest=now&amp;form.app_uuid=${_.get(props, "app.id")}`)}
          {renderLink("API Rate Limit Investigator", `https://splunk.herokai.com/app/search/stevos_api_rate_limit_investigator_beta?form.agent=*`)}
        </ul>

      </div>
      </Card>
    )
  }

  return render()
}

export default Links
