import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  Icon,
  IconSettings,
  Tooltip
} from '@salesforce/design-system-react';

const PropertyValue = props => {

  const [hasCopied, setHasCopied] = useState(false);

  function render() {
    return (
      <React.Fragment>
        <IconSettings iconPath="/assets/icons">
          {renderValue()}
          {renderCliboardAction()}
        </IconSettings>
      </React.Fragment>
    );
  }

  function renderValue() {
    if (valueIsNull()) {
      return <span>-</span>
    } else if (valueIsBoolean()) {
      if (props.value === true) {
        return(<span>Yes</span>)
      } else {
        return(<span>No</span>)
      }
    } else if (valueIsValidUrl()) {
      return(<a href={props.value} target="_blank">{props.value}&nbsp;<Icon category="utility" colorVariant="light" size="xx-small" name="new_window" /></a>)

    } else if (valueIsObj()) {
      return(<span>{JSON.stringify(props.value)}</span>)

    } else {
      return renderLinkedValue()
    }
  }

  function renderLinkedValue() {
    if (props.link !== null) {
      return <span><a href={props.link}>{props.value}</a></span>
    } else {
      return <span>{props.value}</span>
    }
  }

  function clipboardIcon() {
    if (hasCopied) {
      return "check"
    } else {
      return "copy"
    }
  }

  function renderCliboardAction() {
    if (valueIsNull() === false && (props.clipboard || props.clipboardValue)) {
      return(<Tooltip	id="tooltip" align="right" content="Copy">
          <span onClick={handleCopyToClipboard} className="site-cursor-pointer_hover">
            &nbsp;<Icon category="utility" colorVariant="light" size="xx-small" name={clipboardIcon()} />
          </span>
        </Tooltip>)
    }
  }
  function handleCopyToClipboard(e) {
    navigator.clipboard.writeText(props.clipboardValue || props.value)
    setHasCopied(true)
    setTimeout(() => {
      setHasCopied(false)
    }, 3000);
  }

  function valueIsValidUrl() {
    let url;
    try {
      url = new URL(props.value);
      return true
    } catch (_) {
      return false;
    }
  }

  function valueIsBoolean() {
    return typeof(props.value) === 'boolean'
  }

  function valueIsObj() {
    return typeof(props.value) === 'object'
  }

  function valueIsNull() {
    return(props.value === undefined || props.value === null || props.value === '')
  }

  return render()
}

PropertyValue.defaultProps = {
  clipboard: false,
  clipboardValue: null,
  link: null
}

PropertyValue.propTypes = {
  clipboard: PropTypes.bool,
  clipboardValue: PropTypes.string
};

export default PropertyValue
