import React from "react"
import axios from 'axios'

import Chart from 'chart.js/auto'
import {Interaction} from 'chart.js'

import 'chartjs-adapter-moment'

import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';
Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate

class LoadChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      chart: null
    };
  }

  componentDidMount () {
    // this.props.dataLoader(this, 'load');
    const _this = this
    const type = 'load'
    axios.get(`${this.props.app_id}/metrics/${type}`,
      {headers: {
        'Accept': 'application/vnd.heroku+json; version=2',
          // 'Authorization': this.props.auth_string
      }})
      .then(function (response) {
        console.log(response);
        _this.setState({
          data: response.data
        }, () => {
          _this.renderChart()
        })

      })
      .catch(function (error) {
        console.log(error);
      })
  }

  getDatasets () {
    var refined_data = [];
    var datasets = [];
    var _this = this;
    delete _this.state.data.data['load_min'];

    Object.keys(_this.state.data.data).forEach(function(set) {
      if (['load.avg.1m.min','load.avg.1m.max', 'load.avg.1m.mean'].includes(set)) {
        datasets.push({
          label: _this.getLabel(set),
          data: _this.state.data.data[set],
          backgroundColor: _this.getBackgroundColor(set),
          borderColor: _this.getBorderColor(set),
          borderWidth: 1,
          radius: 0,
          pointHitRadius: 10,
          stepped: "middle"
        });
      }
    });
    return datasets;
  }

  getBackgroundColor (set) {
    switch(set) {
      case 'load.avg.1m.min': return '#65a5ec';
      case 'load.avg.1m.max': return '#ecf4fd';
      case 'load.avg.1m.mean': return '#c3dbf8';
    }
  }

  getBorderColor (set) {
    switch(set) {
      case 'load.avg.1m.min': return '#65a5ec';
      case 'load.avg.1m.max': return '#c3dbf8';
      case 'load.avg.1m.mean': return '#65a5ec';
    }
  }

  getLabel (set) {
    switch(set) {
      case 'load.avg.1m.min': return '1M Load Min';
      case 'load.avg.1m.max': return '1M Load Max';
      case 'load.avg.1m.mean': return '1M Load Avg';
    }
  }

  renderChart () {
    var ctx = document.getElementById("load");
    if (this.state.data.data) {
      var myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.props.labeller(this),
          datasets: this.getDatasets().reverse()
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          scales: this.props.scaleConfig,
          plugins: {
            tooltip: {
              animate: false,
              caretPadding: 10,
              mode: 'index',
              intersect: false
            },
            crosshair: {
              sync: {
                enabled: true
              },
              line: {
                color: '#F66',  // crosshair line color
                width: 1        // crosshair line width
              },
            },
            legend: {
              position: "top",
              align: "start"
            }
          }
        }
      })
      this.setState({ chart: myChart })
    }
  }

  render () {
    return (
      <canvas id="load" width="1000" height="250"></canvas>
    )
  }

}

export default LoadChart
