import _ from "lodash";
import React, {useEffect, useState} from 'react';
import axios from "axios";

import { 
  Icon,
  IconSettings,
  Popover
} from '@salesforce/design-system-react';

import DefinitionItem from "../Utilities/DefinitionItem"
import PropertyValue from "../Utilities/PropertyValue"
import Warnings from "../Utilities/Warnings"
import Warning from "../Utilities/Warning";
import TabLabel from "../Utilities/TabLabel";
import TabLoading from "../Utilities/TabLoading";

const DetailsTab = props => {

  const [data, setData] = useState(null)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Details</TabLabel>)
    axios.get(`/spaces/${props.space_id}/details.json`).then((response) => {
        console.log(response.data)
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Details</TabLabel>)
    }).catch((error) => {
      console.log('error loading space data')
      console.log(error)
    })
  }, [])

  function render() {
    if (data) {
      return(
        <React.Fragment key="app-details">

          <Warnings warnings={data.warnings} />

          <h2 className="slds-text-heading_medium">Space</h2>
          <dl className="slds-dl_horizontal">
            <DefinitionItem title="Name">
              <PropertyValue value={data.space.name} clipboard={true} />
            </DefinitionItem>
            <DefinitionItem title="ID">
              <PropertyValue value={data.space.id} clipboard={true} />
            </DefinitionItem>
            <DefinitionItem title="Shield">
              <PropertyValue value={data.space.shield} />
            </DefinitionItem>
            <DefinitionItem title="State">
              <PropertyValue value={data.space.state} />
            </DefinitionItem>
          </dl>

          <h2 className="slds-text-heading_medium slds-m-top_large">CIDRs</h2>
          <dl className="slds-dl_horizontal">
            <DefinitionItem title="CIDR">
              <PropertyValue value={data.space.cidr} clipboard={true} />
            </DefinitionItem>
            <DefinitionItem title="Data CIDR">
              <PropertyValue value={data.space.data_cidr} clipboard={true} />
            </DefinitionItem>
          </dl>

          <h2 className="slds-text-heading_medium slds-m-top_large">Outbound IPs</h2>
          <dl className="slds-dl_horizontal">
            <DefinitionItem title="State">
              <PropertyValue value={data.space.outbound_ips.state} clipboard={true} />
            </DefinitionItem>
            <DefinitionItem title="IPs">
                {data.space.outbound_ips.sources.map((ip) => {
                    return <React.Fragment><PropertyValue value={ip} clipboard={true} /><br /></React.Fragment>
                })}
            </DefinitionItem>
          </dl>


        </React.Fragment>
      ) 
    } else { 
      return(<TabLoading />)
    }
  }

  return render()
}

export default DetailsTab
