import React from 'react';
import { 
    Icon, 
    IconSettings,
  } from "@salesforce/design-system-react";

const ValidIDNotice = props => {
  const { visible } = props;
  function render() {
    console.log(visible)
    if (!visible) return null;
    return(
    <div style={{ minWidth: "100%", margin: "auto", marginBottom: 15 }} className="slds-m-bottom_small slds-notify slds-notify_toast slds-theme_error" role="status">
      <span className="slds-assistive-text">error</span>
      <div className="slds-notify__content">
        <p>Not a valid {props.type}.</p>
      </div>
      <div onClick={() => props.setVisible(false)} className="slds-notify__close">
        <button className="slds-button slds-button_icon slds-button_icon-inverse" title="Close">
          <IconSettings iconPath="/assets/icons">
            <Icon 
              category="utility"
              colorVariant="default"
              size="xx-small"
              name="close"
              style={{ fill: "white" }}
            />
          </IconSettings>
          <span className="slds-assistive-text">Close</span>
        </button>
      </div>
    </div>
    )
  }

  return render()
}

export default ValidIDNotice