import React from "react"
import PropTypes from "prop-types"
class FixedTooltip extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      enabled: true
    }
  }

  swatchStyles() {
    return {
      backgroundColor: this.state.enabled ? this.props.color : '#e5e5e5'
    }
  }

  displayValue() {
    return this.props.value === null ? '-' : this.props.value
  }

  toggle() {
    if (!this.props.chart) { return }
    var meta = this.props.chart.getDatasetMeta(this.props.datasetIndex)

    meta.hidden = meta.hidden === null ? !this.props.chart.data.datasets[this.props.datasetIndex].hidden : null
    this.setState({
      enabled: !meta.hidden
    })

    this.props.chart.update(0)
  }

  render () {
    var className = (this.state.enabled? '' : 'disabled')+" "+this.props.className+" fixed-tooltip"
    return (
      <div className={className} onClick={this.toggle.bind(this)} >
        <div>
          <div className="tooltip-swatch" style={this.swatchStyles()}></div>
          <div className="tooltip-value">
            <span className="text-uppercase tooltip-name">{this.props.name}</span>
            <span>{this.displayValue()}</span>
          </div>
        </div>
      </div>
    )
  }

}

export default FixedTooltip
