import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';

import {
  Button,
  Icon,
  IconSettings,
  Input,
  Modal,
  ScopedNotification
} from '@salesforce/design-system-react';

import PropertyValue from "../Utilities/PropertyValue"
import Warnings from "../Utilities/Warnings"
import TabLabel from '../Utilities/TabLabel';
import TabLoading from '../Utilities/TabLoading';

const AddonsTab = props => {

  const [data, setData] = useState(null)
  const [selectedAddonForModal, setSelectedAddonForModal] = useState(null)

  useEffect(() => {
    // Hack to get the reason field focused
    setTimeout(() => {
      const reasonInput = document.getElementById("reason")
      reasonInput && reasonInput.focus()
    }, 200)
  }, selectedAddonForModal)

  useEffect(() => {
    props.setTabLabel(<TabLabel isLoading={true}>Add-ons</TabLabel>)
    axios.get(`/apps/${props.app_id}/addons.json`).then((response) => {
      setData(response.data)
      props.setTabLabel(<TabLabel isLoading={false} hasWarnings={!_.isEmpty(response.data.warnings)}>Add-ons ({response.data.addons.length})</TabLabel>)
    }).catch((error) => {
      console.log('error loading add-on data')
    })
  }, [])

  function render() {
    if (data) {
      return(
        <React.Fragment key="app-addons">

          <Warnings warnings={data.warnings} />

          <h2 className="slds-text-title_caps slds-m-top_large slds-m-bottom_small">Add-ons</h2>

          <table className="slds-table slds-table_bordered">
            <thead>
              <tr>
                <th>Name / Plan</th>
                <th>Resource Name</th>
                <th>Billing App</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.addons.map((addon) => {
                return(
                  <tr key={addon.id}>
                    <td>
                      <a href={`https://elements.heroku.com/addons/${addon.addon_service.name}`} target="_blank">{addon.addon_service.name}</a><br />
                      <span className="slds-text-body_small slds-text-color_weak slds-m-right_small">{addon.plan.name}</span>
                      </td>
                    <td>
                      <PropertyValue value={addon.name} clipboard={true} /> <br />
                      {
                        addon.attachments && addon.attachments.sort((a, b) => {
                          if (a.app.name < b.app.name) { return -1 }
                          else if (a.app.name > b.app.name) { return 1 }
                          else { return 0 }
                        }).map((attachment) => {
                          return (
                            <React.Fragment key="app-addons">
                              <span className="slds-text-body_small slds-text-color_weak slds-m-right_small">attached as {attachment.name} on <a href=
                              {`/apps/${attachment.app.name}`}>{attachment.app.name}</a></span><br />
                            </React.Fragment>
                          )
                        })
                      }
                      </td>
                    <td><PropertyValue value={addon.billing_entity.name} link={`/apps/${addon.billing_entity.name}`} /></td>
                    <td>
                    </td>
                  </tr>
                )
              })}
                </tbody>
              </table>

            <h2 className="slds-text-title_caps slds-m-top_large slds-m-bottom_small">Log drains</h2>
            { !_.isEmpty(data.drains) &&
            <table className="slds-table slds-table_bordered">
              <thead>
                <tr>
                  <th>ID / Addon</th>
                  <th>URL</th>
                </tr>
              </thead>

              <tbody>
                {data.drains.map((drain) => {
                  return(
                    <tr key={drain.id}>
                      <td>
                        <span>{drain.id}</span> <br />
                        { drain.addon && <span>Add-on: {_.get(drain, "addon.name")}</span> }
                        { !drain.addon && <span>Manually configured</span> }
                        </td>
                      <td  className="slds-truncate slds-cell-wrap">{drain.url}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            }
            {
              _.isEmpty(data.drains) &&
              <p>There are no drains setup for this app</p>
            }

            </React.Fragment>
      )
    } else {
      return(<TabLoading />)
    }
  }

  return render()
}

export default AddonsTab
