import React, {useEffect, useState} from 'react';

import axios from 'axios'
import _ from "lodash"

import { 
    Button,
    Card,
    Combobox,
    Icon,
    IconSettings,
    Input,
    InputIcon,
    WelcomeMat,
    WelcomeMatTile
} from '@salesforce/design-system-react';

import {MalibuIcon} from '@heroku/react-malibu'

const HomePage = props => {

    const [welcomeMatIsOpen, setWelcomeMatIsOpen] = useState(true)

  function render() {
    return(
        <React.Fragment>
            <IconSettings iconPath="/assets/icons">
        {!welcomeMatIsOpen && 
            <Card hasNoHeader={true} style={{width: "50%", margin: "200px auto 0"}}>
                <div className="slds-m-around_large">
                <Combobox 
                assistiveText={{ label: 'Search' }} 
                events={{
                //   onSelect: (e, data) => {
                //     window.location.replace(data.selection[0].url)
                //   },
                  onSubmit: (e) => {
                    window.location.replace(`/search?query=${e.target.value}`)
                  }
                }}
                id="home-search" 
                labels={{ placeholder: "Search for Apps ..."}} 
                // options={history()}
              />
                </div>
            </Card>
        }
        <WelcomeMat
        labels={{
            title: 'Heroku Support Console',
            description:
                'Support Console is not Purple Tab',
        }}
        onRenderInfoActions={() => (
            <div>
            <MalibuIcon name="logo-block-28" size={32} />
            <Icon category="utility" name="add" size="x-small" />
            <Icon category="utility" name="salesforce1" />
            </div>
        )}
        isOpen={welcomeMatIsOpen}
        onRequestClose={ (e) => {setWelcomeMatIsOpen(!welcomeMatIsOpen)} }
        variant="info-only"
    >
        <WelcomeMatTile
            title="Ask Questions, Get Help"
            description={<span><a href='https://heroku.slack.com/archives/C1N712KFH' target='_blank'>#Support</a> on Heroku Slack</span>}
            icon={<Icon category="utility" name="help_center" />}
            id="welcome-mat-tile-1"
            // href="https://example.com"
        />
        <WelcomeMatTile
            title="Report Bugs, Request Features"
            description={<span><a href="https://heroku.slack.com/archives/C028J3Z84MC" target="_blank">#heroku-support-console</a> on the Heroku Slack, or email <a href="mailto:heroku-support-experience@salesforce.com">heroku-support-experience@salesforce.com</a></span>}
            icon={<Icon category="utility" name="bug" />}
            id="welcome-mat-tile-2"
            // href="https://example.com"
        />
        <WelcomeMatTile
            title="User Preferences"
            description="Coming soon"
            icon={<Icon category="utility" name="slider" />}
            id="welcome-mat-tile-4"
            // href="https://example.com"
        />
    </WelcomeMat>
    </IconSettings>
    </React.Fragment>
    )
  }

  return render()
}

export default HomePage