import React from "react"
import axios from 'axios'
import _ from "lodash"

import Chart from 'chart.js/auto'
import {Interaction} from 'chart.js'

import 'chartjs-adapter-moment'

import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';
Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate

class MemoryUsageChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      chart: null
    };
  }

  componentDidMount () {
    const _this = this
    const type = 'memory'
    axios.get(`${this.props.app_id}/metrics/${type}`,
      {headers: {
        'Accept': 'application/vnd.heroku+json; version=2'
          // 'Authorization': this.props.auth_string
      }})
      .then(function (response) {
        _this.setState({
          data: response.data
        }, () => {
          _this.renderChart()
        })

      })
      .catch(function (error) {
        console.log(error);
      })
  }

  getDatasets () {
    var refined_data = [];
    var datasets = [];
    var _this = this;

    Object.keys(_this.state.data.data).forEach(function(set) {
      datasets.push({
        set: set,
        label: _this.getLabel(set),
        data: _this.resizeUnits(_this.state.data.data[set]),
        backgroundColor: _this.getBackgroundColor(set),
        borderColor: _this.getBorderColor(set),
        borderWidth: 1,
        borderDash: _this.getBorderDash(set),
        radius: 0,
        pointHitRadius: 10,
        stepped: "middle"
      });
    });
    return datasets;
  }

  resizeUnits (data) {
    return( data.map(function(point) {
      return parseInt(point / 1024 / 1024);
    }));
  }

  sortDatasets (dataset) {
    var order = [ 'memory.used.bytes.mean', 'memory.total.bytes.max', 'memory.rss.bytes.max', 'memory.swap.bytes.max', 'memory.quota.bytes.max'];
    var _dataset = [];
    order.forEach(function(key) {
      var found = false;
      let items = dataset.filter(function(item) {
        if (!found && item['set'] == key) {
            _dataset.push(item);
            found = true;
            return false;
        } else {
            return true;
        }
      })
    });
    return _dataset;
  }

  getBackgroundColor (set) {
    switch(set) {
      case 'memory.used.bytes.mean': return 'rgba(255,255,255,0.1)';
      case 'memory.rss.bytes.max': return '#f4f2f8';
      case 'memory.swap.bytes.max': return '#ded6e6';
      case 'memory.quota.bytes.max': return 'rgba(255,255,255,0.1)';
      case 'memory.total.bytes.max': return 'rgba(255,255,255,0.1)';
    }
  }

  getBorderColor (set) {
    switch(set) {
      case 'memory.used.bytes.mean': return '#4591e9';
      case 'memory.rss.bytes.max': return '#f4f2f8';
      case 'memory.swap.bytes.max': return '#ded6e6';
      case 'memory.quota.bytes.max': return '#b3bac5';
      case 'memory.total.bytes.max': return '#9a83b6';
    }
  }

  getLabel (set) {
    switch(set) {
      case 'memory.used.bytes.mean': return 'Avg. Total';
      case 'memory.rss.bytes.max': return 'Max RSS';
      case 'memory.swap.bytes.max': return 'Max Swap';
      case 'memory.quota.bytes.max': return 'Mem Quota';
      case 'memory.total.bytes.max': return 'Max Total';
    }
  }

  getBorderDash (set) {
    if (set == 'memory_quota') { return [5,5] }
    return [];
  }

  renderChart () {
    var ctx = document.getElementById("memory_usage");
    if (this.state.data.data) {
      var myChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.props.labeller(this),
            datasets: this.sortDatasets(this.getDatasets())
          },
          plugins: [
          ],
          options: {
            responsive: true,
            maintainAspectRatio: true,
            scales: this.props.scaleConfig,
            events: [
              'mousemove'
            ],
            plugins: {
              tooltip: {
                animate: false,
                caretPadding: 10,
                mode: 'index',
                intersect: false
              },
              crosshair: {
                sync: {
                  enabled: true
                },
                line: {
                  color: '#F66',  // crosshair line color
                  width: 1        // crosshair line width
                },
              },
              legend: {
                position: "top",
                align: "start"
              }
            }
          }
      });

      this.setState({ chart: myChart })
    }
  }

  render () {
    return (
      <canvas id="memory_usage" width="1000" height="250"></canvas>
    )
  }

}

export default MemoryUsageChart