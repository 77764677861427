Array.prototype.sumArray = function (arr) {
  var sum = [];
  if (arr != null && this.length == arr.length) {
    for (var i = 0; i < arr.length; i++) {
      sum.push(this[i] + arr[i]);
    }
  }
  return sum;
}

import React from "react"
import axios from 'axios'

import Chart from 'chart.js/auto'
import {Interaction} from 'chart.js'

import 'chartjs-adapter-moment'

import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';
Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate

class ThroughputChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      datasets: [],
      chart: null
    };
  }

  componentDidMount () {
    const _this = this
    const type = 'status'
    axios.get(`${this.props.app_id}/metrics/${type}`,
      {headers: {
        'Accept': 'application/vnd.heroku+json; version=2',
          'Authorization': this.props.auth_string
      }})
      .then(function (response) {
        _this.setState({
          data: response.data
        }, () => {
          _this.renderChart()
        })

      })
      .catch(function (error) {
        console.log(error);
      })

  }

  getDatasets () {
    var refined_data = [];
    var datasets = [];
    var _this = this;

    Object.keys(_this.state.data.data).forEach(function(set) {
      if (refined_data[set[0] + 'xx'] === undefined) {
        refined_data[set[0] + 'xx'] = _this.state.data.data[set];
      } else {
        refined_data[set[0] + 'xx'] = refined_data[set[0] + 'xx'].sumArray(_this.state.data.data[set]);
      }
    });

    // We're gonna bucket all of 1xx-3xx together into 2xx, so make sure that at least exists.
    if (refined_data['2xx'] === undefined && Object.keys(refined_data).length > 0) {
        refined_data['2xx'] = new Array(Object.values(refined_data)[0].length)
        refined_data['2xx'].fill(0)
    }

    if (refined_data['1xx'] !== undefined) {
      refined_data['2xx'] = refined_data['2xx'].sumArray(refined_data['1xx']);
      delete refined_data['1xx'];
    }
    if (refined_data['3xx'] !== undefined) {
      refined_data['2xx'] = refined_data['2xx'].sumArray(refined_data['3xx']);
      delete refined_data['3xx'];
    }
    if (refined_data['4xx'] !== undefined) {
      refined_data['2xx'] = refined_data['2xx'].sumArray(refined_data['4xx']);
      delete refined_data['4xx'];
    }

    Object.keys(refined_data).forEach(function(set) {
      datasets.push({
        label: _this.getLabel(set),
        data: refined_data[set],
        backgroundColor: _this.getBackgroundColor(set),
        borderColor: _this.getBorderColor(set),
        borderWidth: 1,
        radius: 0,
        pointHitRadius: 10,
        stepped: "middle"
      });
    });

    this.setState({
      datasets: Object.keys(refined_data).reverse()
    })

    return datasets;
  }

  getBackgroundColor (set) {
    switch(set) {
      case '2xx': return '#f6f9fa';
      case '5xx': return '#e29fa1';
    }
  }

  getBorderColor (set) {
    switch(set) {
      case '2xx': return '#94b1bc';
      case '5xx': return '#cd4649';
    }
  }

  getLabel (set) {
    switch(set) {
      case '2xx': return 'OK (2xx-4xx)';
      case '5xx': return 'Failed (5xx)';
    }
  }

  renderChart () {
    var ctx = document.getElementById("throughput");
    if (this.state.data.data) {
      var myChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.props.labeller(this),
            datasets: this.getDatasets().reverse()
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
            scales: this.props.scaleConfig,
            plugins: {
              tooltip: {
                animate: false,
                caretPadding: 10,
                mode: 'index',
                intersect: false
              },
              crosshair: {
                sync: {
                  enabled: true
                },
                line: {
                  color: '#F66',  // crosshair line color
                  width: 1        // crosshair line width
                },
              },
              legend: {
                position: "top",
                align: "start"
              }
            }
          }
      });

      this.setState({ chart: myChart })
    }
  }

  render () {
    return (
      <canvas id="throughput" width="1000" height="250"></canvas>
    )
  }

}

export default ThroughputChart
